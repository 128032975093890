<template>
   <!-- 导航 -->
   <nav class="navbar navbar-expand-lg navbar-light bg-white animate__animated animate__bounceInDown">
            <div class="container">
                <!-- logo -->
                <a class="navbar-brand" href="#">
                    <img src="@/assets/images/logo.png" width="130" class="d-inline-block align-top" alt="">
                </a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
                    aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse" id="navbarCollapse">
                    <ul class="navbar-nav mt-4 mt-lg-0 ml-auto">
                        <li class="nav-item active">
                            <a class="nav-link" href="#">首页 <span class="sr-only">(current)</span></a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#intro">介绍</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#product">产品</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#price">定价</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#team">团队</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#contact">联系</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
</template>

<script>
export default {

}
</script>

<style>

</style>