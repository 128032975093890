<template>
    <section class="slice slice-lg pt-lg-6 pb-0 pb-lg-6 bg-section-secondary" id="product">
        <div class="container">
            <div class="row mb-5 justify-content-center text-center">
                <div class="col-lg-6">
                    <span class="badge badge-soft-success badge-pill badge-lg">
                        PRODUCT
                    </span>
                    <h2 class="mt-4">产品介绍</h2>
                    <div class="mt-2">
                        <p class="lead lh-180">Product introduction.</p>
                    </div>
                </div>
            </div>
            <!-- Card -->
            <div class="row mt-5">
                <div class="col-md-4 hvr-grow" v-for="item in product" :key="item.id">
                    <div class="card">
                        <div class="card-body pb-5">
                            <div class="pt-4 pb-5">
                                <img :src="item.svg" class="img-fluid img-center" style="height: 150px;"
                                    alt="Illustration">
                            </div>
                            <h5 class="h4 lh-130 mb-3">{{ item.title }}</h5>
                            <p class="text-muted mb-0">
                                {{ item.desc }}
                            </p>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            product: [
                { id: 1, svg: require('@/assets/svg/program.svg'), title: '程序编程', desc: '语言范围：HTML5、CSS3、JavaScript、Vue、Nodejs、Java、Jsp、Spring、SpringMvc、SpringBoot、C#' },
                { id: 2, svg: require('@/assets/svg/class.svg'), title: '毕业设计', desc: '服务范围：设计稿->网页、前端页面、后端程序、管理系统、项目报告、UI设计、PS出图、LOGO设计、作业代写' },
                { id: 3, svg: require('@/assets/svg/website.svg'), title: '站点搭建', desc: '站点类型：个人博客、企业官网、个人导航、优惠券、API站、游戏、流量主小程序、趣味小程序、多功能CPS、返利CPS' }
            ]
        }
    }
}
</script>

<style>

</style>