<template>
  <section class="slice slice-lg bg-section-secondary animate__animated animate__rollIn" id="price">
            <div class="container text-center">
                <div class="row justify-content-center mb-6">
                    <div class="col-lg-8">
                        <!-- Title -->
                        <h2 class="h1 strong-600">
                            选择您的定制方案
                        </h2>
                        <!-- Text -->
                        <p class="text-muted">
                           Choose your customized solution.
                        </p>
                    </div>
                </div>
                <!-- Pricing -->
                <div class="row justify-content-center">
                    <div class="col-lg-4 col-md hvr-sink">
                        <div class="card card-pricing text-center px-3 hover-scale-110">
                            <div class="card-header py-5 border-0 delimiter-bottom">
                                <div class="h1 text-center mb-0">￥<span class="price font-weight-bolder">9.9元+</span></div>
                                <span class="h6 text-muted">基础方案</span>
                            </div>
                            <div class="card-body">
                                <ul class="list-unstyled text-sm mb-4">
                                    <li class="py-2">1 end product</li>
                                    <li class="py-2">Use for personal or a client</li>
                                    <li class="py-2">Use in a free end product</li>
                                    <li class="py-2">6 months technical support</li>
                                </ul>
                                <a href="#"
                                    class="btn btn-sm btn-warning hover-translate-y-n3 hover-shadow-lg mb-3">立即选购</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md hvr-sink">
                        <div class="card card-pricing bg-dark text-center px-3 border-0 hover-scale-110">
                            <div class="card-header py-5 border-0 delimiter-bottom">
                                <div class="h1 text-white text-center mb-0">￥<span
                                        class="price font-weight-bolder">199元+</span></div>
                                <span class="h6 text-white">定制方案</span>
                            </div>
                            <div class="card-body">
                                <ul class="list-unstyled text-white text-sm opacity-8 mb-4">
                                    <li class="py-2">1 end product</li>
                                    <li class="py-2">Use for personal or a client</li>
                                    <li class="py-2">Use in a free end product</li>
                                    <li class="py-2">Use in an end product that is <strong
                                            class="text-success text-underline--dashed">sold</strong></li>
                                    <li class="py-2">6 months technical support</li>
                                </ul>
                                <a href="#"
                                    class="btn btn-sm btn-white hover-translate-y-n3 hover-shadow-lg mb-3">立即选购</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-5 text-center">
                    <p class="mb-2">
                        没有想要的套餐，需要更多的服务？
                    </p>
                    <a href="https://work.weixin.qq.com/kfid/kfc1ef26d8e2964af9f" class="text-primary text-underline--dashed">联系我们<svg xmlns="http://www.w3.org/2000/svg"
                            width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-arrow-right ml-2">
                            <line x1="5" y1="12" x2="19" y2="12"></line>
                            <polyline points="12 5 19 12 12 19"></polyline>
                        </svg></a>
                </div>
            </div>
        </section>
        
</template>

<script>
export default {

}
</script>

<style>

</style>