<template>
    <div class="main">
       <NavBar></NavBar>
        <!-- 首页banner -->
        <Banner></Banner>
        <!-- 介绍 -->
       <Intro></Intro>
        <!-- 产品 -->
       <Product></Product>
        <!--定价 -->
        <Price></Price>
        <!-- 底部 -->
        <Footer></Footer>
      
    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import Footer from '@/components/Footer.vue'
import Banner from '@/components/Banner.vue'
import Intro from '@/components/Intro.vue'
import Product from '@/components/Product.vue'
import Price from '@/components/Price.vue'
export default {
    data(){
        return{

        }
    },
    components:{
        NavBar,
        Banner,
        Intro,
        Product,
        Price,
        Footer
    }

}
</script>

<style scoped>

</style>
