<template>
  <div class="animate__animated animate__fadeIn">
    <section class="slice slice-lg bg-section-dark pt-5 pt-lg-8" id="intro">
            <!-- SVG separator -->
            <div class="shape-container shape-line shape-position-top shape-orientation-inverse">
                <svg width="2560px" height="100px" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="none" x="0px" y="0px"
                    viewBox="0 0 2560 100" style="enable-background:new 0 0 2560 100;" xml:space="preserve" class="">
                    <polygon points="2560 0 2560 100 0 100"></polygon>
                </svg>
            </div>

            <div class="container position-relative zindex-100">
                <div class="col">
                    <div class="row justify-content-center">
                        <div class="col-md-10 text-center">
                            <div class="mt-4 mb-6">
                                <h2 class="h1 text-white">
                                    为什么选择我们
                                </h2>
                                <h4 class="text-white mt-3">Why choose us?</h4>
                                <!-- Play button -->
                                <a href="#" class="btn btn-primary btn-icon mt-4">了解更多</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- 介绍内容 -->
        <section class="slice pt-0">
            <div class="container position-relative zindex-100">
                <div class="row">
                    <div class="hvr-shrink col-xl-4 col-sm-6 mt-n7">
                        <div class="card">
                            <div class="d-flex p-5">
                                <div>
                                    <span class="badge badge-warning badge-pill">SERVICE</span>
                                </div>
                                <div class="pl-4">
                                    <h5 class="lh-130">服务</h5>
                                    <p class="text-muted mb-0">
                                        高效的服务效率 <br />Efficient service efficiency.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="hvr-shrink col-xl-4 col-sm-6 mt-sm-n7">
                        <div class="card">
                            <div class="d-flex p-5">
                                <div>
                                    <span class="badge badge-success badge-pill">TYPES</span>
                                </div>
                                <div class="pl-4">
                                    <h5 class="lh-130">种类</h5>
                                    <p class="text-muted mb-0">
                                        可供选择的种类多 <br />There are many kinds to choose from.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="hvr-shrink col-xl-4 col-md-12 col-sm-6 mt-xl-n7">
                        <div class="card">
                            <div class="d-flex p-5">
                                <div>
                                    <span class="badge badge-danger badge-pill">TECHNOLOGY</span>
                                </div>
                                <div class="pl-3">
                                    <h5 class="lh-130">技术</h5>
                                    <p class="text-muted mb-0">
                                        熟练的技术让您满意 <br />Skilled technology makes you satisfied.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="hvr-shrink col-xl-4 col-sm-6">
                        <div class="card">
                            <div class="d-flex p-5">
                                <div>
                                    <span class="badge badge-info badge-pill">UPDATE</span>
                                </div>
                                <div class="pl-4">
                                    <h5 class="lh-130">迭代</h5>
                                    <p class="text-muted mb-0">
                                        紧跟时代最新技术 Keep up with the latest technology.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="hvr-shrink col-xl-4 col-md-12 col-sm-6">
                        <div class="card">
                            <div class="d-flex p-5">
                                <div>
                                    <span class="badge badge-primary badge-pill">KEFU</span>
                                </div>
                                <div class="pl-3">
                                    <h5 class="lh-130">售后</h5>
                                    <p class="text-muted mb-0">
                                        7*24小时的售后服务<br />7 * 24-hour after-sales service.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="hvr-shrink col-xl-4 col-sm-6">
                        <div class="card">
                            <div class="d-flex p-5">
                                <div>
                                    <span class="badge badge-dark badge-pill">MORE</span>
                                </div>
                                <div class="pl-4">
                                    <h5 class="lh-130">待更新</h5>
                                    <p class="text-muted mb-0">
                                        更多业务持续更新中<br>More businesses are continuously updated.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>