<template>
  <section class="slice py-7 animate__animated animate__bounceIn">
            <div class="container">
                <div class="row row-grid align-items-center">
                    <div class="col-12 col-md-5 col-lg-6 order-md-2 text-center">
                        <!-- Image -->
                        <figure class="w-100">
                            <img alt="Image placeholder" src="@/assets/svg/banner.svg" class="img-fluid mw-md-120">
                        </figure>
                    </div>
                    <div class="col-12 col-md-7 col-lg-6 order-md-1 pr-md-5">
                        <!-- Heading -->
                        <h1 class="display-4 text-center text-md-left mb-3">
                            卫梦科技 <strong class="text-primary">链接新一代</strong>
                        </h1>
                        <!-- Text -->
                        <p class="lead text-center text-md-left text-muted">
                            Weimeng Technology Link to the New Generation
                        </p>
                    </div>
                </div>
            </div>
        </section>
</template>

<script>
export default {

}
</script>

<style>

</style>