<template>
    <footer class="position-relative animate__animated animate__bounce" id="footer-main">
        <div class="footer pt-lg-7 footer-dark bg-dark">
            <!-- SVG shape -->
            <div class="shape-container shape-line shape-position-top shape-orientation-inverse">
                <svg width="2560px" height="100px" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="none" x="0px" y="0px"
                    viewBox="0 0 2560 100" style="enable-background:new 0 0 2560 100;" xml:space="preserve"
                    class=" fill-section-secondary">
                    <polygon points="2560 0 2560 100 0 100"></polygon>
                </svg>
            </div>
            <!-- Footer -->
            <div class="container pt-4">
                <div class="row">
                    <div class="col-lg-4 mb-5 mb-lg-0">
                        <!-- Theme's logo -->
                        <a href="index.html">
                            <img alt="Image placeholder" src="@/assets/images/logo_footer.png" width="240" id="footer-logo">
                        </a>
                        <!-- Webpixels' mission -->
                        <p class="mt-4 text-sm opacity-8 pr-lg-4">
                            卫梦科技-让科技将生活变得更美好<br>Weimeng Technology - Let Technology Make Life Better
                        </p>

                    </div>
                    <div class="col-lg-2 col-6 col-sm-4 ml-lg-auto mb-5 mb-lg-0">
                        <h6 class="heading mb-3">旗下站点</h6>
                        <ul class="list-unstyled">
                            <li><a href="https://blog.ihtry.com" target="_blank">卫梦博客</a></li>
                            <li><a href="https://pay.ihtry.com" target="_blank">卫梦支付</a></li>
                            <li><a href="https://www.youwujie.cn" target="_blank">优物街</a></li>
                            <li><a href="https://iwork.youwujie.cn" target="_blank">iwork</a></li>
                        </ul>
                    </div>
                    <div class="col-lg-2 col-6 col-sm-4 mb-5 mb-lg-0">
                        <h6 class="heading mb-3">联系我们</h6>
                        <ul class="list-unstyled">
                            <li><a href="mailto:support@ihtry.com">support@ihtry.com</a></li>
                            <li><a href="tencent://message/?uin=65446398&Site=Sambow&Menu=yes">QQ:65446398</a></li>
                            <li><a href="https://work.weixin.qq.com/kfid/kfc1ef26d8e2964af9f">在线客服</a></li>
                        </ul>
                    </div>
                    <div class="col-lg-2 col-6 col-sm-4 mb-5 mb-lg-0">
                        <h6 class="heading mb-3">关注我们</h6>
                        <ul class="list-unstyled">
                            <li>
                                <img src="http://code-static.oss-cn-beijing.aliyuncs.com/static/images/wx_platform.jpg" alt=""
                                    class="rounded w-80">
                            </li>
                        </ul>
                    </div>
                </div>
                <hr class="divider divider-fade divider-dark my-4">
                <div class="row align-items-center justify-content-md-between pb-4">
                    <div class="col-md-6">
                        <div class="copyright text-sm font-weight-bold text-center text-md-left">
                            © 2020-2023 <a href="https://www.ihtry.com" class="font-weight-bold"
                                target="_blank">卫梦科技</a>. All rights reserved
                        </div>
                    </div>
                    <div class="col-md-6">
                        <ul class="nav justify-content-center justify-content-md-end mt-3 mt-md-0">
                            <li class="nav-item">
                                <a class="nav-link" href="http://beian.miit.gov.cn">
                                    渝ICP备20001889号-6
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link"
                                    href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50023602000421">
                                    <img src="http://cdn.static.ihtry.com/static/images/beian.png" alt="">
                                    渝公网安备50023602000421号
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {

}
</script>

<style>

</style>